import React from 'react'
import Helmet from 'react-helmet'

const NotFoundPage = () => {
  return (
    <Helmet>
      <style type="text/css">
        {`
          body {
            background-color: #111;
          }
        `}
      </style>
    </Helmet>
  )
}

export default NotFoundPage
